import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import IndexPage from "./topics-1";

const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const tech_blog = () => (
    <Layout>
        <Seo title="Asterisk保守運用"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="asterisk_operation">Tech Blog</h1>

            <Tabs>
                <TabList>
                    {/*<Tab>2024</Tab>*/}
                    <Tab>2023</Tab>
                    {/*<Tab>2023</Tab>*/}
                </TabList>

                {/*<TabPanel>*/}

                {/*</TabPanel>*/}

                <TabPanel>

                    <Link to="/blog/20230225" style={linkStyle} rel="noreferrer noopener">
                        <p>2023.02.25 : (Youtube動画) [Asterisk] Setting up pjsip.conf to connect to Rakuten OpenGate /
                            楽天OpenGateへ接続するためのpjsip.conf設定</p>
                    </Link>

                    <Link to="/blog/20230205" style={linkStyle} rel="noreferrer noopener">
                        <p>2023.02.03 : (Youtube動画) How to Install Asterisk with Source Code /
                            Asteriskをソースコードからインストール</p>
                    </Link>
                </TabPanel>

                {/*<TabPanel>*/}

                {/*</TabPanel>*/}
            </Tabs>


            <hr style={hrStyle}/>

            <ContactUs/>
        </div>
    </Layout>
)

export default tech_blog
